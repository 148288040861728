import * as React from "react";
// @ts-ignore
import headerPic from '../../../static/images/nb0.jpg';
// @ts-ignore
import shapeImage from '../../../static/images/header_shape.svg';
// @ts-ignore
import homebckgr from '../../../static/images/fon2.png';

export default class HeaderSection extends React.Component {
    render() {
        return (
            <section className="header">
                <div className="background">
                    <img className="background_img" src={homebckgr}/>
                </div>
                <div className="container_head">
                    <div className="info">
                        <h1 className="title">Удобный софт</h1>
                        <p>Проектирование, разработка и техническое сопровождение информационных систем любой сложности.
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}
