import * as React from "react";
// @ts-ignore
import lettersPatternImage from '../../../static/images/letters_pattern.svg';

export default class ContactsSection extends React.Component<any, any> {
    readonly formUrl = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSczHlK42snGr0MDU0ZF7l3lbodB6tXz0m-sbpAXfBwoFHPnyA/formResponse';

    constructor(props) {
        super(props);
        this.state = {
            ['entry.1106775656']: '',    // name
            ['entry.481773321']: '',   // email
            ['entry.540078450']: '',     // comment
            ['entry.862072362']: '',     // agreement
            validationErrors: {
                ['entry.1106775656']: '',
                ['entry.481773321']: '',
                ['entry.540078450']: '',
                ['entry.862072362']: ''
            },
            isFormSent: false,
            isFormSentError: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        const stateCopy: any = {...this.state};
        const [name, email, comment] = [
            this.state['entry.1106775656'],
            this.state['entry.481773321'],
            this.state['entry.540078450']
        ]
        if (!name.trim()) {
            stateCopy.validationErrors['entry.1106775656'] = 'Укажите ваше имя';
            stateCopy.isFormInvalid = true;
            this.setState(stateCopy);
            return false;
        } else {
            stateCopy.validationErrors['entry.1106775656'] = '';
        }

        if (!email.trim()) {
            stateCopy.validationErrors['entry.481773321'] = 'Укажите ваш e-mail';
            this.setState(stateCopy);
            return false;
        } else {
            stateCopy.validationErrors['entry.481773321'] = '';
        }

        if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            stateCopy.validationErrors['entry.481773321'] = 'Указанный e-mail не является корректным адресом';
            this.setState(stateCopy);
            return false;
        } else {
            stateCopy.validationErrors['entry.481773321'] = '';
        }

        if (!comment.trim()) {
            stateCopy.validationErrors['entry.540078450'] = 'Сообщение не должно быть пустым';
            this.setState(stateCopy);
            return false;
        } else {
            stateCopy.validationErrors['entry.540078450'] = '';
        }

        return true;
    }

    handleChange(event) {
        this.setState({...this.state, [event.target.name]: event.target.value})
    }

    handleChangeCheckbox(event) {
        this.setState({...this.state, [event.target.name]: event.target.checked})
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.validateForm()) {
            return;
        }

        this.sendForm()
            .then(res => {
                this.setState({...this.state, isFormSent: true, isFormError: false});
                setTimeout(() => {
                    this.clearFormState();
                    this.setState({...this.state, isFormSent: false});
                }, 3000);
            })
            .catch(err => {
                console.log(err);
                this.setState({...this.state, isFormSent: false, isFormError: true});
            })
    }

    sendForm() {
        const request: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST'
            },
            mode: 'no-cors'
        };
        const params = [
            `entry.1106775656=${this.state['entry.1106775656']}`,
            `entry.481773321=${this.state['entry.481773321']}`,
            `entry.540078450=${this.state['entry.540078450']}`,
            `entry.862072362=${this.state['entry.862072362']}`,
            `submit=Submit`
        ];
        const query = this.formUrl + '?' + params.join('&');
        return fetch(query, request);
    }

    clearFormState() {
        this.setState({
            ['entry.1106775656']: '',    // name
            ['entry.481773321']: '',   // email
            ['entry.540078450']: '',     // comment
            ['entry.862072362']: '',     // comment
            validationErrors: {
                ['entry.1106775656']: '',
                ['entry.481773321']: '',
                ['entry.540078450']: '',
                ['entry.862072362']: ''
            },
            isFormSent: false,
            isFormInvalid: false,
            isFormSentError: false
        });
    };

    renderFormBlock() {
        return (
            !this.state.isFormSent && <>
                <div className="input-group">
                    <input type="text"
                           name="entry.1106775656"
                           value={this.state['entry.1106775656']}
                           placeholder="Представьтесь, пожалуйста"
                           onChange={this.handleChange}
                           className={this.state.validationErrors['entry.1106775656'] ? 'invalid' : ''}/>
                    {
                        this.state.validationErrors['entry.1106775656'] && <div
                            className="validation-error">{this.state.validationErrors['entry.1106775656']}</div>
                    }
                </div>
                <div className="input-group">
                    <input type="email"
                           name="entry.481773321"
                           value={this.state['entry.481773321']}
                           placeholder="Ваш E-mail"
                           onChange={this.handleChange}
                           className={this.state.validationErrors['entry.481773321'] ? 'invalid' : ''}/>
                    {
                        this.state.validationErrors['entry.481773321'] && <div
                            className="validation-error">{this.state.validationErrors['entry.481773321']}</div>
                    }
                </div>
                <div className="input-group">
                        <textarea
                            name="entry.540078450"
                            value={this.state['entry.540078450']}
                            placeholder="Ваш вопрос или предложение"
                            onChange={this.handleChange}
                            className={this.state.validationErrors['entry.540078450'] ? 'invalid' : ''}/>
                    {
                        this.state.validationErrors['entry.540078450'] && <div
                            className="validation-error">{this.state.validationErrors['entry.540078450']}</div>
                    }
                </div>
                <div className="input-group agreement">
                    <label>
                        <input name="entry.862072362"
                               value={this.state['entry.862072362']}
                               onChange={this.handleChangeCheckbox}
                               type="checkbox"/>Согласен на обработку персональных данных</label>
                </div>
                <button type="submit" className={this.state['entry.862072362'] != true ? 'disabled' :  ''}>Отправить</button>
            </>
        );
    }

    renderForm() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h3>Связаться с нами</h3>
                {!this.state.isFormSent && this.renderFormBlock()}
                {this.state.isFormError &&
                <div className="error">Ошибка отправки формы. Попробуйте повторить позднее.</div>}
                {this.state.isFormSent && !this.state.isFormError &&
                <div className="success">Спасибо за ваше обращение. Мы ответим на указанный e-mail.</div>}
            </form>
        )
    }

    renderContacts() {
        return (
            <div className="info">
                <h2 className="title">Контакты</h2>
                <p>Телефон: <a href="tel:+79168058417">+7(916) 805-84-17</a></p>
                <p>E-mail: <a href="mailto:info@comfortsoft.ru">info@comfortsoft.ru</a></p>
            </div>
        )
    }

    render() {
        return (
            <section id="contacts" className="contacts container">
                <div className="container">
                    <div className="wrapper">
                        {this.renderContacts()}
                        {this.renderForm()}
                    </div>
                </div>
                <img className="shape" src={lettersPatternImage} alt="Фон"/>
            </section>
        )
    }
}
