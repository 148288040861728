import * as React from "react";
import { scrollToElementId } from "../../helpers";

export default class AboutSection extends React.Component {
    render() {
        return (
            <section id="about" className="about container">
                <h2 className="title">О компании</h2>
                <p>Наша команда имеет большой опыт реализации разнообразных информационных систем, порталов, сайтов и мобильных приложений.
                    Осуществляем полный цикл разработки: от бизнес-аналитики, проектирования и прототипирования до внедрения и сопровождения систем.
                Предлагаем удобные, прозрачные ИТ-решения, которые позволяют значительно сократить трудозатраты и автоматизировать рутинные бизнес-процессы.</p>
            </section>
        )
    }
}
