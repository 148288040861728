import * as React from "react";
import HeaderSection from "./HeaderSection";
import AboutSection from "./AboutSection";
import ServicesSection from "./ServicesSection";
import ContactsSection from "./ContactsSection";
import TechSection from "./TechSection";
import ProjectsSection from "./ProjectsSection";

export default class HomePage extends React.Component {
    render() {
        return (
            <>
                <HeaderSection/>
                <AboutSection/>
                <ServicesSection/>
                <TechSection/>
                <ProjectsSection/>
                <ContactsSection/>
            </>
        )
    }
}
